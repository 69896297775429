// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Hero from "../components/hero"
import Block from "../components/block90vh"
import Formulario from "../components/multiFormulario/multitab"
import Img from "../assets/images/bgHeroMantenimiento.jpg"
import ImgMantenimiento from "../assets/images/mantenimientoContacto.jpg"
import Data from "../data/mantenimientoAsesoria"
export default function Gracias() {
  return (
    <>
      <Helmet>
        <title>Mantenimiento y Asesoria</title>
        <meta name="description" content="Mantenimiento y Asesoria"></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title="MANTENIMIENTO Y ASESORIA"></Hero>
        {/* los bloques de mantenimiento y asesoria */}
        <Block boton={true} data={Data}/>
        {/* los tabs de los formularios */}
        <Formulario title1='MANTENIMIENTO' title2='ASESORÍA' img1={ImgMantenimiento} img2={ImgMantenimiento}/>
      </Layout>
    </>
  )
}
