import Img1 from "../assets/images/mantenimiento01.jpg"
import Img2 from "../assets/images/asesoria01.jpg"
export default [
  {
    title: "MANTENIMIENTO",
    text:
      "Diseños de los procesos de ensamblaje bajo el concepto de DISEÑO MODULAR. Para el desarrollo de nuestros productos hemos virado hacia su diseño empezando por el mapeo e identificación de componentes estandarizados desarrollados por proveedores de los grandes fabricantes internacionales. Nuestros componentes de fabricación propia se ven reducidos; iniciamos su fabricación teniendr su costo y asegurando la rentabilidad del fabricante.",
    image: Img1,
  },
  {
    title: "ASESORÍA",
    text:
      "Diseños de los procesos de ensamblaje bajo el concepto de DISEÑO MODULAR. Para el desarrollo de nuestros productos hemos virado hacia su diseño empezando por el mapeo e identificación de componentes estandarizados desarrollados por proveedores de los grandes fabricantes internacionales. Nuestros componentes de fabricación propia se ven reducidos; iniciamos su fabricación teniendr su costo y asegurando la rentabilidad del fabricante.",
    image: Img2,
  },
]
